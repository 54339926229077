.borderTab::before {
	bottom: -5px;
}

.borderTab.borderTab_tablet {
	text-align: center;
	padding-left: 16px;
	padding-right: 16px;
}

.borderTab.borderTab_mobile {
	margin-left: 0;
}
